import { User, LoginResponse, ErrorResponse, UserResponse } from "../interfaces/type"
import Config from '../config.json'
import toast from "react-hot-toast";
import { redirect } from "react-router-dom";

export const loginUser = async (data: Partial<User>) : Promise<Partial<LoginResponse | ErrorResponse>> => {
    // return fetch(`${Config.API_URL}/auth/login`,
    //     {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //     .then(
    //         response => {
                
    //             return response.json()
    //         })
    //     .then(data => {
    //         return data;
    //     })

        const response = await fetch(`${Config.API_URL}/auth/login`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
          });
        
          if (!response.ok) {
            throw new Error("Failed to fetch comments.");
          }
        
          const res = await response.json();
        
          if (res.status === 500) {
            let error = res as ErrorResponse;
            toast.error(`${error.error} - ${error.errorDetail}`);
          }
          return res ?? {};
}

export const userInformation = async ( token : String) : Promise<Partial<UserResponse & ErrorResponse>> => {
    return fetch(`${Config.API_URL}/auth/userInformation`,
        { 
            headers: { 
                'Authorization': `Bearer ${token}`
            }
        })
        .then(
            response => {
                return response.json()
            })
        .then(data => {
            return data;
        })
}