import { Key, useState } from "react";
import { LinkMenuType, UserPermission } from "../../interfaces/type";
import {
  FaUser,
  FaRetweet,
  FaFilePdf,
  FaCogs,
  FaTachometerAlt,
  FaFile,
  FaSortDown,
} from "react-icons/fa";
import {
  FaA,
  FaMoneyBillTransfer
} from "react-icons/fa6";
import LinkMenu from "./linkmenu";

type Props = {
  open: Boolean;
  userPermission: Partial<UserPermission>[]
};

let LinkMenuTree: LinkMenuType[] = [
  {
    id: 1,
    name: "Dashboard",
    url: "/dashboard",
    icon: <FaTachometerAlt />,
    show: false,
  },
  {
    id: 2,
    name: "Credito",
    url: "/",
    icon: <FaMoneyBillTransfer />,
    show: false,
    child: [
      {
        id: 1,
        name: "Nuevo",
        url: "/nuevo-credito",
        icon: <FaFile />,
        show: false,
      },
      {
        id: 2,
        name: "Seguimiento",
        url: "/seguimiento-credito",
        icon: <FaRetweet />,
        show: false,
      },
    ],
  },
  {
    id: 3,
    name: "Demanda",
    url: "/",
    icon: <FaFile />,
    show: false,
    child: [
      {
        id: 1,
        name: "Nuevo",
        url: "/nueva-demanda",
        icon: <FaFile />,
        show: false,
      },
      {
        id: 2,
        name: "Seguimiento",
        url: "/seguimiento-demanda",
        icon: <FaRetweet />,
        show: false,
      },
    ],
  },
  {
    id: 4,
    name: "Reportes",
    url: "/",
    icon: <FaFilePdf />,
    show: false,
    child: [
      {
        id: 1,
        name: "Demandas",
        url: "/reporte-demandas",
        icon: <FaFile />,
        show: false,
      },
      {
        id: 2,
        name: "Notificaciones",
        url: "/reporte-notificaciones",
        icon: <FaFile />,
        show: false,
      },
    ],
  },
  {
    id: 5,
    name: "Configuracion",
    url: "/",
    icon: <FaCogs />,
    show: false,
    child: [
      {
        id: 1,
        name: "Usuarios",
        url: "/usuarios",
        icon: <FaUser />,
        show: false,
      },
      {
        id: 2,
        name: "Constantes",
        url: "/constantes",
        icon: <FaA />,
        show: false,
      },
    ],
  },
];

function Menu({ open, userPermission }: Props) {
  const [menuItems, setMenuItems] = useState(LinkMenuTree);
  const handleShowLink = (index: number) => {
    menuItems.forEach(item => {
      item.show = false;
    })
    let menu = menuItems.find((item) => item.id === index);
    if (menu) {
      menu.show = !menu.show;
      setMenuItems([...menuItems]);
    }
  };
  return (
    <nav aria-label="alternative nav">
      <div
        className={`bg-gray-800 shadow-xl h-20 fixed bottom-0 mt-12 md:relative md:h-screen z-10 w-full ${
          open ? "md:w-48" : "md:w-16"
        } content-center`}
      >
        <div
          className={`md:mt-12 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between ${
            open ? "md:w-48" : "md:w-16"
          }`}
        >
          <ul className="flex flex-row md:flex-col pt-3 md:py-3 px-1 md:px-2 text-center md:text-left">
            {menuItems.map((link) => {
              return link.child ? (
                <li className="flex-1" key={link.id as Key}>
                  <a
                    href="#"
                    onClick={() => handleShowLink(link.id)}
                    className="flex flex-row py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-white text-sm"
                    aria-controls={`${link.name}`}
                    data-collapse-toggle={`${link.name}`}
                  >
                    <div
                      className={`${
                        link.name !== ""
                          ? "w-1/4 justify-center"
                          : "w-full justify-center"
                      } flex`}
                    >
                      {link.icon}
                    </div>
                    <div className="w-3/4">{link.name}</div>
                    <FaSortDown />
                  </a>
                  <ul
                    id={`${link.name}`}
                    className={`flex-1 ml-5 ${link.show ? "" : "hidden"}`}
                    key={link.id as Key}
                  >
                    {link.child.map((item) => {
                      return (
                        <li key={`sub${item.id}` as Key}>
                          <LinkMenu
                            url={item.url}
                            name={open ? item.name : ""}
                            icon={item.icon}
                            child={item.child}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ) : (
                <li className="flex-1" key={link.id as Key}>
                  <LinkMenu
                    url={link.url}
                    name={open ? link.name : ""}
                    icon={link.icon}
                    child={link.child}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Menu;
