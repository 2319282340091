import React, { createContext, useReducer } from 'react';
import { AuthState, User } from '../interfaces/type';
import { storeReducer } from '../services/authReducer';

type Props = {
    children?: JSX.Element | JSX.Element[]
}

export type AuthContextProps = {
    auth: AuthState
    loginAuth: ( data: AuthState ) => void;
    setUser: ( data: Partial<User> ) => void;
    logoutAuth: () => void;
} 

const INITIAL_STATE : AuthState = {
    token: localStorage.getItem('token') || '',
}

const AuthProvider = (props: Props) => {
    const [ auth, dispatch] = useReducer( storeReducer, INITIAL_STATE );

    const loginAuth = ( data: AuthState ) => {
        localStorage.setItem('token', `${data.token}`);
        dispatch({ type: 'login', payload: data })
    }

    const setUser = ( data: Partial<User> ) => {
        dispatch({ type: 'setUser', payload: data })
    }

    const logoutAuth = () => {
        localStorage.removeItem('token');
        dispatch({ type: 'logout' })
    }

    return (
        <AuthContext.Provider value={{ auth, loginAuth, setUser, logoutAuth }} >
            {props?.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);