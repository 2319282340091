import React, { useContext, useState } from 'react';
import { FaAlignCenter, FaAlignLeft, FaBell, FaSignOutAlt } from 'react-icons/fa'
import { AuthContext } from '../../providers/auth.provider';
import NotificationBtn from '../../components/notification'
import { editNotificationService } from '../../services/notification.service';
import toast from 'react-hot-toast';
import { ErrorResponse, Notification } from '../../interfaces/type';

type Props = {
    open: Boolean
    handleOpen: () => void
}

function HeaderAdmin({ open, handleOpen }: Props) {
    const [ dropdown, setDropdown ] = useState(false)
    const { auth, logoutAuth, setUser } = useContext(AuthContext);
    
    const handleView = async (item: Partial<Notification>) => {
        item.new = !item.new;
        var response = await editNotificationService(item);
        if (response.status == 200) {
            setUser({...auth.user, notification: [...auth.user?.notification ?? []]})
        }
        if (response.status === 500) {
          toast.error((response as ErrorResponse).error);
        }
      };
    return (
        <header>
            <nav aria-label="menu nav" className="bg-gray-800 pt-2 md:pt-1 pb-1 px-1 mt-0 h-auto fixed w-full z-20 top-0">

                <div className="flex flex-wrap items-center">
                    <div className="flex flex-shrink md:w-1/3 justify-center md:justify-start text-white">
                        <button onClick={handleOpen}>
                            {
                                open ? <FaAlignLeft className='w-16' /> : <FaAlignCenter className='w-16' />
                            }
                        </button>
                        <a href="#" aria-label="Home">
                            <span className="text-xl pl-2"><i className="em em-grinning"></i>LECOBRO</span>
                        </a>
                    </div>

                    <div className="flex flex-1 md:w-1/3 justify-center md:justify-start text-white px-2">

                    </div>

                    <div className="flex w-full pt-2 content-center justify-between md:w-1/3 md:justify-end">
                        <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
                            <li className="flex-1 md:flex-none md:mr-3">
                                <NotificationBtn notification={auth.user?.notification??[]} handleView={handleView}/>
                            </li>
                            <li className="flex-1 md:flex-none md:mr-3">
                                <div className="relative inline-block text-left">
                                    <div>
                                        <button type="button" onClick={() => setDropdown(!dropdown)} className={`${ dropdown ? 'bg-gray-50 text-gray-700' : 'text-white '} inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-50 hover:text-gray-700`} id="menu-button" aria-expanded="true" aria-haspopup="true">
                                            Hola, {auth.user?.name}
                                            <svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className={`${ dropdown ? '' : 'hidden'} absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
                                        {/* <div className="py-1" role="none">
                                            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex={-1} id="menu-item-0">Edit</a>
                                            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex={-1} id="menu-item-1">Duplicate</a>
                                        </div>
                                        <div className="py-1" role="none">
                                            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex={-1} id="menu-item-2">Archive</a>
                                            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex={-1} id="menu-item-3">Move</a>
                                        </div>
                                        <div className="py-1" role="none">
                                            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex={-1} id="menu-item-4">Share</a>
                                            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabIndex={-1} id="menu-item-5">Add to favorites</a>
                                        </div> */}
                                        <div className="py-1" role="none">
                                            <button onClick={logoutAuth} className="text-gray-700 px-4 py-2 text-sm flex flex-row items-center w-full" role="menuitem" tabIndex={-1}><FaSignOutAlt className='mr-5' />Salir</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </nav>
        </header>
    );
}

export default HeaderAdmin;