import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getDetailNotificationService } from "../services/notification.service";
import { Notification } from "../interfaces/type";
import moment from "moment";
import { FaTimes } from "react-icons/fa";

type Props = {
  folio: string;
  id: string;
  handleCloseDetail: () => void;
};

const NotificationDetail = ({ folio, id, handleCloseDetail }: Props) => {
  const [detail, setDetail] = useState<Partial<Notification>[]>([]);

  const { data, isLoading, error } = useQuery<Partial<Notification>[]>(
    ["notificationDetail"],
    () => getDetailNotificationService(id)
  );

  useEffect(() => {
    setDetail([]);
  }, []);

  useEffect(() => {
    setDetail(data ? data : []);
  }, [data]);

  return (
    <div className=" flex flex-row h-screen fixed inset-0 z-50 outline-none focus:outline-none pt-20 pb-20 bg-gray-800 bg-opacity-50">
      <div className="relative w-full h-20 px-40">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className=" w-full bg-gray-50 max-h-96 overflow-y-auto rounded">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">
                Detalle Expediente {folio}
              </h3>
              <button
                onClick={handleCloseDetail}
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              >
                <FaTimes size={20} />
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              {isLoading ? (
                <div className="flex justify-center items-center">
                  Cargando...
                </div>
              ) : (
                <></>
              )}
              {detail.length == 0 && !isLoading ? (
                <div className="flex justify-center items-center">
                  Sin Información
                </div>
              ) : (
                <></>
              )}
              {detail.length > 0 && !isLoading ? (
                <div className="h-full w-full flex">
                  <table className="w-full table-auto text-left text-xs">
                    <thead className="bg-gray-50">
                      <tr className="text-center">
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                          FECHA
                        </th>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                          SECRETARÍA
                        </th>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                          ASUNTO
                        </th>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                          PARTES
                        </th>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                          SÍNTESIS
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {detail
                        .sort((a, b) => {
                          if (typeof b.createdAt === "undefined") {
                            return 1;
                          }
                          if (typeof a.createdAt === "undefined") {
                            return -1;
                          }
                          if (a.createdAt === b.createdAt) {
                            return 0;
                          }
                          return a.createdAt < b.createdAt ? 1 : -1;
                        })
                        .map((item) => {
                          return (
                            <tr key={item.createdAt?.toString()}>
                              <td className="p-4 border-b border-blue-gray-50 w-28">
                                {moment(item.createdAt).format("DD-MM-YYYY")}
                              </td>
                              <td className="p-4 border-b border-blue-gray-50">
                                {item.id}
                              </td>
                              <td className="p-4 border-b border-blue-gray-50">
                                {item.folio}
                              </td>
                              <td className="p-4 border-b border-blue-gray-50">
                                {item.title}
                              </td>
                              <td className="p-4 border-b border-blue-gray-50">
                                {item.description}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                onClick={handleCloseDetail}
                className="text-red-500 background-transparent font-bold uppercase px-5 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <>
    //   <div className="justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none">
    //     <div className="relative w-full h-20 px-40">
    //       <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
    //         <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
    //           <h3 className="text-xl font-semibold">
    //             Detalle Expediente {folio}
    //           </h3>
    //           <button
    //             onClick={handleCloseDetail}
    //             className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
    //           >
    //             <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"></span>
    //           </button>
    //         </div>
    //         <div className="relative p-6 flex-auto">
    //           {isLoading ? (
    //             <div className="flex justify-center items-center">
    //               Cargando...
    //             </div>
    //           ) : (
    //             <></>
    //           )}
    //           {detail.length == 0 && !isLoading ? (
    //             <div className="flex justify-center items-center">
    //               Sin Información
    //             </div>
    //           ) : (
    //             <></>
    //           )}
    //           {detail.length > 0 && !isLoading ? (
    //             <div className="h-full w-full overflow-scroll flex">
    //               <table className="w-full table-auto text-left text-xs">
    //                 <thead className="bg-gray-50">
    //                   <tr className="text-center">
    //                     <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
    //                       FECHA
    //                     </th>
    //                     <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
    //                       SECRETARÍA
    //                     </th>
    //                     <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
    //                       ASUNTO
    //                     </th>
    //                     <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
    //                       PARTES
    //                     </th>
    //                     <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
    //                       SÍNTESIS
    //                     </th>
    //                   </tr>
    //                 </thead>
    //                 <tbody className="divide-y divide-gray-200">
    //                   {detail
    //                     .sort((a, b) => {
    //                       if (typeof b.createdAt === "undefined") {
    //                         return 1;
    //                       }
    //                       if (typeof a.createdAt === "undefined") {
    //                         return -1;
    //                       }
    //                       if (a.createdAt === b.createdAt) {
    //                         return 0;
    //                       }
    //                       return a.createdAt < b.createdAt ? 1 : -1;
    //                     })
    //                     .map((item) => {
    //                       return (
    //                         <tr key={item.createdAt?.toString()}>
    //                           <td className="p-4 border-b border-blue-gray-50 w-28">
    //                             {moment(item.createdAt).format("DD-MM-YYYY")}
    //                           </td>
    //                           <td className="p-4 border-b border-blue-gray-50">
    //                             {item.id}
    //                           </td>
    //                           <td className="p-4 border-b border-blue-gray-50">
    //                             {item.folio}
    //                           </td>
    //                           <td className="p-4 border-b border-blue-gray-50">
    //                             {item.title}
    //                           </td>
    //                           <td className="p-4 border-b border-blue-gray-50">
    //                             {item.description}
    //                           </td>
    //                         </tr>
    //                       );
    //                     })}
    //                 </tbody>
    //               </table>
    //             </div>
    //           ) : (
    //             <></>
    //           )}
    //         </div>
    //         {/*footer*/}
    //         <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
    //           <button
    //             onClick={handleCloseDetail}
    //             className="text-red-500 background-transparent font-bold uppercase px-5 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    //             type="button"
    //           >
    //             Cerrar
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    // </>
  );
};

export default NotificationDetail;
