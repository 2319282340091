import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../interfaces/type";
import { AuthContext } from "../providers/auth.provider";
import Header from "./partials/header";
import Menu from "./partials/menu";
import { userInformation } from "../services/auth.service"

type Props = {
    children?: JSX.Element | JSX.Element[];
};

const AdminLayout = ({ children }: Props) => {
    const [ open, setOpen ] = useState(true)
    
    const navigate = useNavigate()
    const { auth, setUser, logoutAuth } = useContext(AuthContext);

    useEffect(() => {
        const api = async ()=> {
            const response = await userInformation(auth.token)
            if (response.status === 200){
                setUser((response.user as Partial<User>))
            }
            if (response.status === 500){
                logoutAuth()
                navigate('/login')
            }
        }
      api()
    }, [])

    const handleOpenMenu = () => {
        setOpen(!open)
    }

    return (
        <div className="bg-gray-800 font-sans leading-normal tracking-normal mt-12">
            <Header open={open} handleOpen={handleOpenMenu} />
            <main>
                <div className="flex flex-col md:flex-row">
                    <Menu open={open} userPermission={auth.user?.userPermission ?? []}/>
                    <section className="flex w-full">
                        <div id="main" className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5">
                            {children}
                        </div>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default AdminLayout;