import React, { lazy, Suspense, useContext } from 'react';
import './App.css';
import { Routes, Route, BrowserRouter, Navigate, Outlet } from 'react-router-dom'
import AuthLayout from './views/auth.layout';
import AdminLayout from './views/admin.layout';
import { AuthContext } from './providers/auth.provider';

const Login = lazy(() => import('./views/login.view'))
const Dashboard = lazy(() => import('./views/dashboard.view'))
const Users = lazy(() => import('./views/users.view'))
const DemandReport = lazy(() => import('./views/demandReport.view'))
const Demand = lazy(() => import('./views/demand.view'))
const DemandList = lazy(() => import('./views/demandList.view'))
const DemandEdit = lazy(() => import('./views/demandEdit.view'))
const Constant = lazy(() => import('./views/constant.view'))
const NotificationReport = lazy(() => import('./views/notificationReport.view'))
const Credit = lazy(() => import('./views/credit.view'))
const CreditList = lazy(() => import('./views/creditList.view'))
const CreditEdit = lazy(() => import('./views/creditEdit.view'))
const Loading = () => <p>Loading ...</p>;

type Props = {
  token: String
}

const ProtecedRoute = ({ token }: Props) => {
  if (token === "") return <Navigate to="/login" replace />

  return <AdminLayout><Outlet /></AdminLayout>
}

function App() {
  const { auth } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
        <Route path="/" element={<Navigate to='/login' />}/>
          <Route path='/login' element={<AuthLayout><Login /></AuthLayout>} />
          <Route element={<ProtecedRoute token={auth.token} />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/nueva-demanda' element={<Demand />} />
            <Route path='/seguimiento-demanda' element={<DemandList />} />
            <Route path='/editar-demanda/:id' element={<DemandEdit />} />
            <Route path='/usuarios' element={<Users />} />
            <Route path='/constantes' element={<Constant />} />
            <Route path='/reporte-demandas' element={<DemandReport />} />
            <Route path='/reporte-notificaciones' element={<NotificationReport />} />
            <Route path='/nuevo-credito' element={<Credit />} />
            <Route path='/seguimiento-credito' element={<CreditList />} />
            <Route path='/editar-credito/:id' element={<CreditEdit />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
