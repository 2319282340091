type Props = {
    children?: JSX.Element | JSX.Element[];
};

const AuthLayout = ({ children }: Props) => {
    return (
        <div className="flex w-full justify-center items-center h-screen">
            <div className="m-auto w-11/12 md:w-1/4">
                {children}
            </div>
        </div>
    )
}

export default AuthLayout;