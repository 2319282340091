import { AuthState } from '../interfaces/type';
import { User } from '../interfaces/type';

type AuthAction =
    | { type: 'login', payload: AuthState }
    | { type: 'setUser', payload: Partial<User> }
    | { type: 'logout' }


export const storeReducer = (state: AuthState, action: AuthAction): AuthState => {

    switch (action.type) {
        case 'login':
            return {
                ...state,
                token: action.payload.token,
            }
        case 'setUser':
            return {
                ...state,
                user: action.payload,
            }
        case 'logout':
            return {
                token: '',
            }
        default:
            return state;
    }

}