import React, { useEffect, useState } from "react";
import { FaBell, FaEye, FaEyeSlash } from "react-icons/fa";
import { ErrorResponse, Notification } from "../interfaces/type";
import { useQuery } from "@tanstack/react-query";
import {
  getNotificationService,
  editNotificationService,
} from "../services/notification.service";
import toast from "react-hot-toast";
import NotificationDetail from "./notificationDetail";

type Props = {
  notification: Partial<Notification>[];
  handleView: (item: Partial<Notification>) => void;
};
const NotificationBtn = ({ notification, handleView }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [folio, setFolio] = useState<string>("");
  const [id, setId] = useState<string>("");

  const handleShow = () => {
    setShow(!show);
  };

  

  const handleShowDetail = (folio: string, id: string) => {
    setFolio(folio);
    setId(id);
    setShowModal(true);
    setShow(!show);
  };

  const handleCloseDetail = () => {
    setFolio("");
    setId("");
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <NotificationDetail
          folio={folio}
          id={id}
          handleCloseDetail={handleCloseDetail}
        />
      ) : null}
      <button
        className="flex flex-row text-white no-underline hover:text-gray-200 hover:text-underline py-2 px-4"
        type="button"
        data-dropdown-toggle="dropdown"
        onClick={handleShow}
      >
        <FaBell />
        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 mt-1 px-1 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          {notification.filter(f => f.new == true).length}
        </span>
      </button>
      <div
        className={`bg-white z-50 divide-gray-100 rounded shadow my-4 absolute right-3 h-96 overflow-auto w-96 ${
          show ? "" : "hidden"
        }`}
        id="dropdown"
      >
        <ul className="py-1 m-2" aria-labelledby="dropdown">
          {notification && notification.length > 0 ? (
            notification
              .sort((a, b) => {
                if (typeof b.id === "undefined") {
                  return 1;
                }
                if (typeof a.id === "undefined") {
                  return -1;
                }
                if (a.id === b.id) {
                  return 0;
                }
                return a.id > b.id ? 1 : -1;
              })
              .map((item) => {
                return (
                  <li className="flex flex-row">
                    <div className="mx-2">
                      <button
                        onClick={() => handleView(item)}
                        className={`${
                          item.new == true ? "text-cyan-600" : "text-gray-700"
                        }`}
                      >
                        {item.new == true ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    <div className="text-xs border-b mb-2 flex flex-col">
                      <button
                        onClick={() =>
                          handleShowDetail(item.folio ?? "", item.id ?? "")
                        }
                        className="font-bold text-cyan-800 text-left"
                      >{`EXP: ${item.folio}`}</button>
                      <span className="mt-2 text-gray-800">{item.title}</span>
                      <span className="mt-2 text-gray-500">
                        {item.description}
                      </span>
                    </div>
                  </li>
                );
              })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </>
  );
};

export default NotificationBtn;
