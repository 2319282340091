import { redirect } from "react-router-dom";
import Config from "../config.json";
import {
  ActionResponse,
  ErrorResponse,
  User,
  Notification,
} from "../interfaces/type";
import toast from "react-hot-toast";

const TOKEN = localStorage.getItem("token");

export const getNotificationService = async (): Promise<
  Partial<Notification>[]
> => {
  const response = await fetch(`${Config.API_URL}/notification`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch comments.");
  }

  const res = await response.json();

  if (response.status === 401) {
    redirect("/");
  }

  if (res.status === 500) {
    let error = res as ErrorResponse;
    toast.error(`${error.error} - ${error.errorDetail}`);
  }

  return res?.data ?? [];
};

export const editNotificationService = async (
  data: Partial<Notification>
): Promise<Partial<ActionResponse | ErrorResponse>> => {
  const response = await fetch(`${Config.API_URL}/notification/${data.id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
  });
  const res = await response.json();
  if (response.status === 401) {
    redirect("/");
  }

  if (res.status === 500) {
    let error = res as ErrorResponse;
    toast.error(`${error.error} - ${error.errorDetail}`);
  }

  return res;
};

export const getDetailNotificationService = async (
  id: string
): Promise<Partial<Notification>[]> => {
  const response = await fetch(`${Config.API_URL}/notification/detail/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch comments.");
  }

  const res = await response.json();

  if (response.status === 401) {
    redirect("/");
  }

  if (res.status === 500) {
    let error = res as ErrorResponse;
    toast.error(`${error.error} - ${error.errorDetail}`);
  }
  return res?.data;
};
