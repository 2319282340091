import { Link } from 'react-router-dom'
import { LinkMenuType } from '../../interfaces/type'
import { FaSortDown } from 'react-icons/fa'

const LinkMenu = ( { url, name, icon, child} : Partial<LinkMenuType>) => {
  return (
    <Link  
      to={`${url}`}
      className="flex flex-row py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-white text-sm"
    >
      <div className={`${name !== '' ? 'w-1/4 justify-center' : 'w-full justify-center'} flex`}>{icon}</div>
      {name && <div className='w-3/4'>{name}</div>}
      {child && <FaSortDown />}
    </Link>
  )
}

export default LinkMenu